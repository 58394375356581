import React, { useEffect,useState } from "react";
import Moment from "react-moment";

import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "../Redux/Actions/orderActions";
import SideBar from "./sideBar";
import { CopyToClipboard } from "react-copy-to-clipboard";
const OrderScreen = ({ match }) => {
  window.scrollTo(0, 0);

  const orderId = match.params.id;
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading } = orderDetails;
  const [walletCopied,setWalletCopied]=useState(false);

  const walletCopiedHandler=()=>{
    setWalletCopied(true);
    setTimeout(()=>{setWalletCopied(false)},2000)
  }
  useEffect(() => {
    if (!order) {
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, order]);

  return (
    <div id="main" className="orderview">
      <SideBar />
     
      <div className="annon">
        <img src="/iii/cloud.png" alt="bbb" />
        <div className="maint">We have opened!</div>
        <div className="btt" style={{ fontSize: "11px" }}>
          You have the opportunity to exchange ATOM/USDT cryptocurrency at a
          very favorable rate! The ATOM/USDT transfer limit is $10,000 for the
          duration of the promotion{" "}
        </div>
        <div
          className="smm"
          style={{ fontSize: "13px", marginTop: "10px !important" }}
        >
          The promotion is valid until 26.09.2023
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n                  @media only screen and (max-width: 1200px){\n                    .annon {\n                        display: none !important;\n                    }   \n                  }\n              ",
        }}
      />
      {/*/.sidebar*/}
      <div className="content-admin">
        <h4
          style={{
            color: "#23282D",
            padding: "30px 0 15px 0",
            fontWeight: 800,
          }}
        >
          Request
        </h4>
        {!loading ? (
          <div className="block-order">
            <table className="tabl">
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{order._id}</td>
                </tr>
                <tr>
                  <td>Date of exchange</td>
                  <td>
                    <Moment>{order.createdAt}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td>
                    {order.sendCount} {order.symbolSend}
                  </td>
                </tr>
                <tr>
                  <td>Exchange rate</td>
                  <td>{order.exchangeRate}</td>
                </tr>
                <tr>
                  <td>Receive</td>
                  <td>
                    {order.exchangeRate * order.sendCount} {order.symbolReceive}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    {order.isPaid ? (
                      <span
                        className="status"
                        style={{
                          backgroundColor: "#33ff33",
                          color: "#222",
                          padding: ".2rem",
                        }}
                      >
                        done
                      </span>
                    ) : (
                      <span
                        className="status"
                        style={{ backgroundColor: "#FFF500", color: "#222" }}
                      >
                        in processing
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Exchange</td>
                  <td>
                    {order.titleSend} =&gt; {order.titleReceive}
                  </td>
                </tr>
                <tr>
                  <td>Recipient wallet</td>
                  <td>{order.receiveNumber}</td>
                </tr>
                <tr style={{ height: "10px" }}></tr>
                <CopyToClipboard onCopy={walletCopiedHandler} text={order.wallet}
                children={<tr>
                  <td>
                    Pay {order.sendCount} {order.symbolSend} at the wallet:{" "}
                    <img
                      src="/iii/copp.svg"
                      alt="copp"
                      className="copp1"
                      style={{ display: "none", cursor: "pointer" }}
                    />
                  </td>
                  <td>
                    {order.wallet}{" "}
                    <img
                          
                          src={`${!walletCopied?"/iii/copp.svg":"/iii/Green_check.svg"}`}
                          alt="copp"
                          className="copp2"
                          style={{ marginLeft: "45px", cursor: "pointer",width:"1.5rem" }}
                        />
                    
                  </td>
                </tr>}
                
                />
              </tbody>
            </table>
            <span
              style={{
                color: "#222",
                fontWeight: 600,
                position: "relative",
                top: "15px",
              }}
            >
              After processing the application, your crypto will be credited to
              your account within 10-20 minutes.
              <br />
              After payment, the transaction status will be "confirmed"
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OrderScreen;
