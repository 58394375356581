import React from "react";
const NotFound = () => {
	window.location.href="/";
	return (
		<div>Not Found {":("}</div>

	);
};

export default NotFound;
